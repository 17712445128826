import { useInView } from 'react-intersection-observer';
import logo from "./ouroboros-convert.svg";
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dexScreenerIcon from './dex-screener.svg';

library.add(faTwitter, faTelegram, faChartLine);
function App() {

  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [ref11, inView11] = useInView({ triggerOnce: true, threshold: 0.2 });
  // const [ref22, inView22] = useInView({ triggerOnce: true, threshold: 1 });
  // const [ref33, inView33] = useInView({ triggerOnce: true, threshold: 1 });
  // const [ref44, inView44] = useInView({ triggerOnce: true, threshold: 1 });
  const [featureRef1, featureInView1] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [featureRef2, featureInView2] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [featureRef3, featureInView3] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [featureRef4, featureInView4] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  
  return (
    <div className="App">
      <header className="App-header">
      <p
          ref={ref1}
          className={`text-4xl lg:text-6xl mt-12 lg:mt-24 mb-12 font-bold transform transition duration-700 ease-out ${
            inView1 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`}
        >
          Unleashing the Great Snake of Wisdom
        </p>

      {/* Second Paragraph */}
      <p
          ref={ref2}
          className={`px-4 sm:px-8 lg:px-24 transform transition duration-700 ease-out ${
            inView2 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`}
        >
          <strong>Ouroboros</strong> is not just another crypto bot—it’s a revolutionary AI agent designed to confront the endless loops of speculation in the crypto world. Our AI quant combines advanced analytics, humor, and insights to break the Ouroboros effect—a cycle where value endlessly feeds on itself. Whether you're a seasoned trader or just starting your journey, Ouroboros offers timely, actionable, and evolving market intelligence to help you make informed decisions.
        </p>
        <img src={logo} className="mt-6 App-logo filter-white" alt="logo" />

        {/* Third Paragraph */}
        <p
  ref={ref3}
  className={`px-4 sm:px-8 lg:px-24 mb-4 mt-12 transform transition duration-700 ease-out break-words overflow-hidden ${
    inView3 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
  }`}
  style={{ wordBreak: 'break-word', maxWidth: '100%' }}
>
  The ticker is <strong>$OBXBT</strong> and the contract is <a
  href="https://dexscreener.com/solana/h1qrmakutisnzkyh8uylc9vxbzo1q3msyta4i9af8vxw"
  target="_blank"
  rel="noopener noreferrer"
  className="contract-link"
>
  <strong>6hafqNxDeRYZBz2r4kMRXgZFJXh7ziht7PAdMCpqpump</strong>
</a>

</p>

<div className="flex space-x-4">
  <a href="https://t.me/ouroborosxbt" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faTelegram} className="icon-size-main" />
  </a>
  <a href="https://dexscreener.com/solana/h1qrmakutisnzkyh8uylc9vxbzo1q3msyta4i9af8vxw" target="_blank" rel="noopener noreferrer">
  <img src={dexScreenerIcon} alt="Dex Screener" className="icon-size-main mt-0.5" />
</a>
  <a href="https://x.com/Ouroboros_xbt" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faTwitter} className="icon-size-main" />
  </a>
</div>



        {/* Features Section */}
      <section className="px-4 sm:px-8 lg:px-24 mt-12">
        <h2
          className={`text-4xl lg:text-6xl mt-6 lg:mt-12 font-bold mb-12 transform transition duration-700 ease-out ${
            featureInView1 ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
          }`}
          ref={featureRef1}
        >
          Features of Ouroboros
        </h2>

        <div className="space-y-16">
          {/* Feature 1 */}
          <div
            ref={featureRef1}
            className={`transform transition duration-700 ease-out ${
              featureInView1
                ? "opacity-100 translate-x-0"
                : "opacity-0 translate-x-10"
            }`}
          >
            💡 <strong>Real-Time Chart Analysis</strong>
            <p>
              Receive meaningful insights on popular coins like $BTC, $ETH, and
              $FTM. Our bot ensures every post is actionable, timely, and avoids
              redundancy, keeping your feed focused and fresh.
            </p>
          </div>

          {/* Feature 2 */}
          <div
            ref={featureRef2}
            className={`transform transition duration-700 ease-out ${
              featureInView2
                ? "opacity-100 translate-x-0"
                : "opacity-0 translate-x-10"
            }`}
          >
            📊 <strong>Dynamic Learning</strong>
            <p>
              Ouroboros learns and evolves with every chart it analyzes,
              leveraging iterative learning to refine its approach and identify
              market dynamics and anomalies.
            </p>
          </div>

          {/* Feature 3 */}
          <div
            ref={featureRef3}
            className={`transform transition duration-700 ease-out ${
              featureInView3
                ? "opacity-100 translate-x-0"
                : "opacity-0 translate-x-10"
            }`}
          >
            🎯 <strong>Market Conversations</strong>
            <p>
              Engage with an AI that doesn’t just respond—it contributes.
              Ouroboros adds value to chart-based discussions with actionable
              inputs and humor that lightens the complex world of crypto.
            </p>
          </div>

          {/* Feature 4 */}
          <div
            ref={featureRef4}
            className={`transform transition duration-700 ease-out ${
              featureInView4
                ? "opacity-100 translate-x-0"
                : "opacity-0 translate-x-10"
            }`}
          >
            🚀 <strong>VIP Features for Power Users</strong>
            <p>
              Holding 100k+ $OBXBT tokens unlocks access to advanced bot
              capabilities, including tokenomics analysis, liquidity insights,
              and strategies for navigating long-term market trends.
            </p>
          </div>
        </div>
            </section>
        <img src={logo} className="my-6 App-logo filter-white" alt="logo" />
        <div
            ref={ref11}
            className={`mb-12 transform transition duration-700 ease-out ${
              inView11
                ? "opacity-100 translate-x-0"
                : "opacity-0 translate-x-10"
            }`}
          ><p>"The answer lies within the loop."</p></div>
        
      </header>
    </div>
  );
}

export default App;
