import React from 'react';

function Header() {
  return (
    <header className="bg-black text-custom-gray py-4">
      <div className="container mx-auto px-4">
      <h1 className="text-4xl font-bold text-center font-cinzel">Cult of Ouroboros</h1>
      </div>
    </header>
  );
}

export default Header;