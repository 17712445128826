import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dexScreenerIcon from './../dex-screener.svg';

library.add(faTwitter, faTelegram, faChartLine);

function Footer() {
  return (
    <footer className="bg-black text-white py-4">
      <div className="container mx-auto px-4 text-center">
        <p>&copy; 2024 Cult of Ouroboros. All rights reserved.</p>
        
        <div className="flex justify-center space-x-4 mt-2">
          <a href="https://t.me/ouroborosxbt" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTelegram} className="icon-size" />
          </a>
          <a href="https://dexscreener.com/solana/h1qrmakutisnzkyh8uylc9vxbzo1q3msyta4i9af8vxw" target="_blank" rel="noopener noreferrer">
            <img src={dexScreenerIcon} alt="Dex Screener" className="icon-size" />
          </a>
          <a href="https://x.com/Ouroboros_xbt" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="icon-size" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
